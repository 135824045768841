import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AppRoute = ({ component: Component, permissions, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout])
	if (permissions.length > 0) {
		const userData = localStorage.getItem('userInfo');
		const userInfo = JSON?.parse(userData);
		if (userInfo) {
			const userPermission = userInfo.user_type;
			const hasPermission = permissions.includes(userPermission);

			if (!hasPermission) {
				return <Navigate to="/dashboard" />;
			} else {
				return (
					<Component {...props} />
				)
			}
		}
	} else {
		return (
			<Component {...props} />
		)
	}
}

export default AppRoute