import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import './style.css';

const AuthFromURL = (props) => {
    useEffect(() => {
        getTokenFromURL();
    }, []);
    return (<>
        <div className='auth-from-url d-flex justify-content-center align-items-center'>
            <div className="loader"></div>
        </div>  </>)
}
const getAuthCodeFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('auth_code');
};
const getTokenFromURL = async values => {
    const authCode = getAuthCodeFromURL();
    if (!authCode) {
        console.error('auth_code not found in URL');
        return;
    }
    const data = {
        auth_code: authCode,
    };

    try {
        let response = await axios.post(`${API_BASE_URL}/user/verify_authcode`, data);
        if (response.data.code === 200 || response.data.code === 201) {
            console.log("data verfiy", response?.data?.data?.access_token);
            localStorage.setItem(AUTH_TOKEN, response?.data?.data?.access_token);
            localStorage.setItem('userData', JSON.stringify(response?.data?.data));
            localStorage.setItem('userInfo', JSON.stringify(response?.data?.data?.user_info));
            if (localStorage.getItem('auth_token')) {
                window.location.href = `/dashboard/profile`;
            }
        } else {
            console.log(response.data.message);
        }
    } catch (error) {
        console.error('No Token:', error);
    }

};
export default AuthFromURL;