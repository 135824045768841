import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	// Add UI state here
	showSignupModal: false,
	showSigninModal: false,
	showForgetPasswordModal: false,
	user: null,
	isAuthenticated: false,
	resetFormTrigger: false,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},
		// Add UI actions here
		setShowSignupModal: (state, action) => {
			state.showSignupModal = action.payload;
		},
		setShowSigninModal: (state, action) => {
			state.showSigninModal = action.payload;
		},
		setShowForgetPasswordModal: (state, action) => {
			state.showForgetPasswordModal = action.payload;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		clearUser: (state) => {
			state.user = null;
			state.isAuthenticated = false;
		},
		resetFormTrigger: (state) => {
			state.resetFormTrigger = !state.resetFormTrigger;
		},
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	setShowSignupModal,
	setShowSigninModal,
	setShowForgetPasswordModal,
	setUser,
	clearUser,
	resetFormTrigger,
} = authSlice.actions

export default authSlice.reducer